export const ContentItemTypes = {
  0: 'product',
  1: 'collection',
  2: 'article',
  3: 'pdf',
  4: 'video',
  5: 'other',
  6: 'own-video',
  7: 'paint',
  8: 'page',
  product: 0,
  collection: 1,
  article: 2,
  pdf: 3,
  video: 4,
  other: 5,
  ownVideo: 6,
  paint: 7,
  page: 8,
};

export const productTypes = {
  rug: 'rug',
  paint: 'Paint',
};
