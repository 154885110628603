export const Breakpoints = {
  MOBILE: 576,
  TABLET: 768,
  LAPTOP: 992,
  DESKTOP: 1200,
};

export const cultures = {
  'en-us': 'USA',
  'en-ca': 'Canada',
  'en-gb': 'Europe',
  'en-ap': 'Asia',
  'en-au': 'Australia',
  'en-cn': 'China',
  'en-in': 'India',
  'es-la': 'Spanish',
  'es-mx': 'Mexico',
  'pt-br': 'Portuguese',
  'en-nz': 'New Zealand',
  'ko-kr': '한국어',
  'ja-jp': '日本語',
  'zh-cn': '简体字"? "简化字',
};

export const DesignAwards = {
  JUDGES_VOTE: 'Judge Voting',
  SUBMISSION: 'Submission',
  PEOPLE_CHOICE: 'PeoplesVoting',
  FINAL_WINNERS: 'FinalWinners',
};

export const requestStatusTypes = {
  idle: 0,
  pending: 3,
  complete: 4,
};

export const imageFilesTypes = [
  { type: 'image/png' },
  { type: 'image/jpeg' },
];
