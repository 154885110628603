export function siteSearch(
  searchCategory,
  searchTerm,
  numberSearchResults,
) {
  window.dataLayer.push({
    event: 'search',
    searchCategory,
    searchTerm,
    numberSearchResults,
  });
}

export function inStockSwitch(buttonState) {
  window.dataLayer.push({
    event: 'product-in-stock-inventory',
    buttonState, // on,off
  });
}

export function inStockInput(inStockQuantity) {
  window.dataLayer.push({
    event: 'form-submit',
    formName: 'in stock inventory',
    submitStatus: 'complete',
    inStockQuantity, // 500,1000, etc
  });
}
