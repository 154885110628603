import { utility } from '../modules/utility';
import { createElement } from '../modules/createElement';
import { productClick } from '../modules/GoogleTagManager/productClicks';
import { productImpression } from '../modules/GoogleTagManager/productImpressions';
import { siteSearch as GTMsiteSearch }  from '../modules/GoogleTagManager/search' ;

(function siteSearchInit() {
    let siteSearch = document.getElementById('searchDropDown');
    if (!siteSearch) {
        return;
    }

    siteSearch = siteSearch.parentElement;

    // LOCALIZATION
    const searchTranslations = JSON.parse($('#siteSearchTranslations').val());
    const sectionLabel = searchTranslations.SectionLabel;
    const typeLabel = searchTranslations.TypeLabel;
    const collectionLabel = searchTranslations.CollectionLabel;
    const tagsLabel = searchTranslations.TagsLabel;
    const styleNumberLabel = searchTranslations.StyleNumberLabel;
    const contentTypeLabel = searchTranslations.ContentTypeLabel;
    const colorLabel = searchTranslations.ColorLabel;

    // HARDCODED DATA
    const ALL_TAB = 'all';
    const PAGES_TAB = 'pages';
    const PRODUCTS_TAB = 'products';
    const RESOURCES_TAB = 'resources';
    const TABLET_SIZE = 992;
    const MAX_TAGS_COUNT = 3;


    // ELEMENTS
    const searchInput = document.getElementById('search-input');
    const resultsWrapper = document.getElementById('results-wrapper');
    const noResultsMessage = document.getElementById('no-results');
    const searchByColorLabel = document.getElementById('search-type-by-color-label');
    // Tabs
    const allTab = document.getElementById('all-search-tab');
    const pagesTab = document.getElementById('pages-search-tab');
    const procutsTab = document.getElementById('products-search-tab');
    const resourcesTab = document.getElementById('resources-search-tab');
    // Buttons
    const viewMoreBtn = document.getElementById('view-more');
    const searchInputBtn = document.getElementById('search-input-btn');

    const resultCountHolder = document.getElementById('result-count-holder');
    const searchedWordHolder = document.getElementById('searched-word-holder');
    const searchResultCounterElement = document.querySelector('.search-result-counter');

    // STATE
    let page = 1;
    let searchTerm = '';
    let oldSearchTerm = '';
    let siteSearchData = [];
    let pagesArr = [];
    let productsArr = [];
    let resourcesArr = [];
    let currentTab = ALL_TAB;
    let previousTab = allTab;
    let totalResults = 0;
    let totalPages = 0;
    let totalProducts = 0;
    let totalResources = 0;
    let isColorsSearchMode = false;


    // DETECT WHERE TO SCROLL
    function scrollToNewElements(allData, newData) {
        const elToScroll = allData.length - newData.length;
        if (newData.length > 0) {
            const elementToFocus = resultsWrapper.getElementsByTagName('li')[elToScroll];
            elementToFocus.scrollIntoView({ behavior: 'smooth' });
            elementToFocus.querySelector('a').focus();
        }
    }

    function resetState() {
        page = 1;

        siteSearchData = [];
        pagesArr = [];
        productsArr = [];
        resourcesArr = [];
    }


    function mapResponseToState(response) {
        pagesArr = [...pagesArr, ...response.Pages];
        productsArr = [...productsArr, ...response.Products];
        resourcesArr = [...resourcesArr, ...response.Resources];
        siteSearchData = [...siteSearchData, ...response.All];
        isColorsSearchMode = response.IsColorsSearchMode;
    }

    function setSearchCounterString(amount) {
        resultCountHolder.textContent = amount;
        searchedWordHolder.textContent = searchTerm;
    }

    function scrollToTopOfResults() {
        resultsWrapper.firstElementChild.scrollIntoView({ behavior: 'smooth' });
    }

    function showViewMoreBtn() {
        viewMoreBtn.classList.remove('d-none');
    }

    function hideViewMoreBtn() {
        viewMoreBtn.classList.add('d-none');
    }

    function createPageElement(item) {
        const {
            Title, Url, Section
        } = item;
        let props = [
        ];

        if (Section.Name !== '' && Title.toLowerCase() !== 'home') {
            props.push({ label: sectionLabel, value: Section.Name });
        }

        const el = createElement({
            element: 'li',
            classes: 'item page',
            children: [
                createElement({
                    element: 'a',
                    classes: 'text-decoration-none stretched-link',
                    attributes: { href: Url },
                    children: [
                        createElement({
                            element: 'span',
                            classes: 'title',
                            text: Title,
                        }),
                    ],
                }),
                ...props.map(({ label, value }) => createElement({
                    element: 'span',
                    classes: 'update pr-md-5',
                    text: `${label}: `,
                    children: [
                        createElement({
                            element: 'span',
                            classes: 'value',
                            text: value,
                        }),
                    ],
                })),
            ],
        });

        resultsWrapper.appendChild(el);
    }

    function createProductElement(item) {
        const {
            Url, Title, StyleNumber, ProductType, Collection, ColorName, ColorNumber,
        } = item;
        const props = [
            { label: typeLabel, value: ProductType },
        ];

        if (Collection && Collection.toLowerCase() !== 'no collection') {
            props.push({ label: collectionLabel, value: Collection });
        }

        if (isColorsSearchMode && ColorName) {
            props.unshift({ label: colorLabel, value: `${ColorName} ${ColorNumber}` });
        }

        const gtmProductData = {
            id: StyleNumber,
            name: Title,
            category: ProductType,
            collectionName: Collection,
            list: 'Navigation Menu Search',
        };

        productImpression(gtmProductData);

        const el = createElement({
            element: 'li',
            classes: 'item product',
            children: [
                createElement({
                    element: 'a',
                    classes: 'text-decoration-none stretched-link',
                    attributes: { href: Url },
                    eventListeners: { click: () => productClick(gtmProductData) },
                    children: [
                        createElement({
                            element: 'span',
                            classes: 'title',
                            text: Title,
                            children: [
                                createElement({
                                    element: 'span',
                                    classes: 'text-uppercase',
                                    text: ` - ${StyleNumber}`,
                                }),
                            ],
                        }),
                    ],
                }),
                ...props.map(({ label, value }) => createElement({
                    element: 'span',
                    classes: 'update pr-4',
                    text: `${label}: `,
                    children: [
                        createElement({
                            element: 'span',
                            classes: 'value',
                            text: value,
                        }),
                    ],
                })),
            ],
        });

        resultsWrapper.appendChild(el);
    }

    function createResourceElement(item) {
        const {
            Title, Url, ContentType, Tags, IsPublic,
        } = item;
        let props = [
            { label: contentTypeLabel, value: ContentType }
        ];

        const el = createElement({
            element: 'li',
            classes: 'item resource',
            children: [
                createElement({
                    element: 'a',
                    classes: 'text-decoration-none stretched-link',
                    attributes: { href: Url },
                    children: [
                        createElement({
                            element: 'span',
                            classes: 'title',
                            text: Title,
                            children: [
                                createElement({
                                    element: 'i',
                                    classes: 'far fa-lock-alt',
                                    shouldRender: IsPublic !== true
                                }),
                            ],
                        }),
                    ],
                }),
                ...props.map(({ label, value }) => createElement({
                    element: 'span',
                    classes: 'update pr-md-5',
                    text: `${label}: `,
                    children: [
                        createElement({
                            element: 'span',
                            classes: 'value',
                            text: value,
                        }),
                    ],
                })),
                createElement({
                    element: 'span',
                    classes: 'type pr-lg-5 pr-md-5',
                    text: `${tagsLabel}: `,
                    shouldRender: Tags.length > 0,
                    children: [
                        ...Tags.slice(0, MAX_TAGS_COUNT).map(({ Name }) => createElement({
                            element: 'span',
                            classes: 'tag d-inline-block font-weight-normal',
                            text: Name,
                        })),
                        createElement({
                            element: 'span',
                            classes: 'tag d-inline-block font-weight-normal',
                            shouldRender: Tags.length > MAX_TAGS_COUNT,
                            children: [
                                createElement({
                                    element: 'i',
                                    classes: 'fal fa-ellipsis-h',
                                }),
                            ],
                        }),
                    ],
                }),
            ],
        });

        resultsWrapper.appendChild(el);
    }

    function createSearchElement(item) {
        switch (item.Type) {
            case 'Page':
                createPageElement(item);
                break;
            case 'Product':
                createProductElement(item);
                break;
            case 'Resource':
                createResourceElement(item);
                break;
            default:
                break;
        }
    }

    function generateTemplate(arr, totalItems, tab = false) {
        arr.forEach((item) => {
            createSearchElement(item);
        });
        const isResultsDisplayed = resultsWrapper.children.length > 0;

        if (arr.length > 0) {
            $(noResultsMessage).hide();
            scrollToTopOfResults();
        } else if (!isResultsDisplayed) {
            $(noResultsMessage).show();
        } else {
            resultsWrapper.lastElementChild.scrollIntoView({ behavior: 'smooth' });
        }

        if (arr.length < 6 || totalResults - arr.length <= 0 || (productsArr && productsArr.length == totalItems.totalPRODUCTS)) {
            hideViewMoreBtn();
        } else {
            showViewMoreBtn();
        }
    }

    // SEARCH DATA
    function newSearchRequest() {
        utility.removeChildren(resultsWrapper);
        resetState();

        const leftPartUrl = document.getElementById('searchLink').value;
        const urlParams = `/${searchTerm}/${page}`;
        const url = leftPartUrl + urlParams;

        $.ajax({
            url,
            type: 'GET',
            data: 'JSON',
            async: true,
            success(response) {
                totalResults = response.TotalItems;

                mapResponseToState(response);
                totalPages = response.totalPAGES;
                totalProducts = response.totalPRODUCTS;
                totalResources = response.totalRESOURCES;

                generateTemplate(siteSearchData, response);

                if (siteSearchData.length > 0) {
                    $(noResultsMessage).hide();
                    scrollToTopOfResults();
                } else {
                    $(noResultsMessage).show();
                }

                if (!isColorsSearchMode) {
                    $(searchByColorLabel).hide();
                } else {
                    $(searchByColorLabel).show();
                }

                setSearchCounterString(totalResults);
                searchResultCounterElement.classList.remove('d-none');
                GTMsiteSearch(currentTab, searchTerm, totalResults)
            },
        });
    }

    function requestMoreData(currentTab) {
        if (resultsWrapper) {
            page++;
            /**
                PARAMS FOR THE REQUEST:
                - page
                - searchTerm
              * */
            const leftPartUrl = document.getElementById('searchLink').value;
            const urlParams = `/${searchTerm}/${page}`;
            const url = leftPartUrl + urlParams;

            $.ajax({
                url,
                type: 'GET',
                data: 'JSON',
                async: true,
                success(response) {
                    mapResponseToState(response);

                    switch (currentTab) {
                        case ALL_TAB:
                            generateTemplate(response.All, response);
                            scrollToNewElements(siteSearchData, response.All);
                            break;

                        case PAGES_TAB:
                            generateTemplate(response.Pages, totalPages, "PAGES_TAB");
                            scrollToNewElements(pagesArr, response.Pages);
                            break;

                        case PRODUCTS_TAB:
                            generateTemplate(response.Products, totalProducts, "PRODUCTS_TAB");
                            scrollToNewElements(productsArr, response.Products);
                            break;

                        case RESOURCES_TAB:
                            generateTemplate(response.Resources, totalResources, "RESOURCES_TAB");
                            scrollToNewElements(resourcesArr, response.Resources);
                            break;
                        default:
                            break;
                    }
                },
            });
        }
    }

    function changeTab(arr, newTab, totalItems = false) {
        if (!Array.isArray(arr)) {
            return;
        }

        currentTab = newTab;
        utility.removeChildren(resultsWrapper);
        generateTemplate(arr, totalItems, newTab);

        if (siteSearchData.length === 0) {
            $(noResultsMessage).show();
        }
    }

    function onClose() {
        const elementsToShow = document.querySelectorAll('.hidden-menu-item');
        if (elementsToShow) {
            elementsToShow.forEach((element) => {
                $(element).show().removeClass('hidden-menu-item');
            });
        }

        if (window.innerWidth >= TABLET_SIZE) {
            utility.stopPreventingBodyScroll();
        }
    }

    function onOpen() {
        utility.preventBodyScroll({ zIndex: 1029, onClose });

        if (window.innerWidth <= TABLET_SIZE) {
            const elementsToHide = document.querySelectorAll('#collapsingPrimaryNavbar .nav-item');
            elementsToHide.forEach((element) => {
                if (!element.classList.contains('search-nav')) {
                    $(element).hide().addClass('hidden-menu-item');
                }
            });
        }
    }


    function changeTabStyle(newTab) {
        previousTab.classList.remove('active');
        newTab.classList.add('active', 'animate');
        previousTab = newTab;
    }

    function makeSearchRequest() {
        searchTerm = searchInput.value.toLowerCase();

        if (searchTerm !== '' && searchTerm !== oldSearchTerm) {
            currentTab = ALL_TAB;
            newSearchRequest();
            changeTabStyle(allTab);
            oldSearchTerm = searchTerm;
        }

        if (!searchTerm && isColorsSearchMode) {
            $(searchByColorLabel).hide();
        }
    }

    // LISTEN FOR EVENTS
    $(siteSearch).on('show.bs.dropdown', onOpen);
    $(siteSearch).on('hide.bs.dropdown', onClose);
    $(siteSearch).on('shown.bs.dropdown', () => {
        searchInput.focus();
    });

    $(allTab).on('click', () => {
        changeTab(siteSearchData, ALL_TAB);
        changeTabStyle(allTab);
    });

    $(pagesTab).on('click', () => {
        changeTab(pagesArr, PAGES_TAB, totalPages);
        changeTabStyle(pagesTab);
    });

    $(procutsTab).on('click', () => {
        changeTab(productsArr, PRODUCTS_TAB, totalProducts);
        changeTabStyle(procutsTab);
    });

    $(resourcesTab).on('click', () => {
        changeTab(resourcesArr, RESOURCES_TAB, totalResources);
        changeTabStyle(resourcesTab);
    });

    $(viewMoreBtn).on('click', () => {
        requestMoreData(currentTab);
    });

    searchInput.addEventListener('keyup', utility.debounce(makeSearchRequest, 500));

    $(searchInputBtn).on('click', makeSearchRequest);
}());
